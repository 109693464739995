















import { defineComponent } from "@vue/composition-api";
import axios from "@/helpers/axios";
import moment from "moment";
moment.locale("zh-cn");

export default defineComponent({
  data() {
    return {
      iccid: "",
      list: []
    };
  },
  methods: {
    setIccid() {
      this.iccid = prompt("输入iccid") || "";
    },
    async getList() {
      this.list = await axios
        .post("/api/manage/hw/sim", { iccid: this.iccid })
        .then(res => res.data.data)
        .catch(() => []);
    },
    formatTime(time: string) {
      return moment(Number(time)).format("lll");
    }
  }
});
